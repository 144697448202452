body {
  font-family: 'Work Sans', sans-serif;
}

/* Custom classnames */
.collapse-padding-0 > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.center-modal-footer .ant-modal-footer {
  text-align: center;
  padding: 24px 0 24px 0;
}

.qr-container-wrapper {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ghostwhite;
  overflow-y: auto;
}

.qr-image-wrapper section {
  border-radius: 16px;
}
.qr-image-wrapper section div {
  box-shadow: unset !important;
}

/* CSS files for overwriting libraries */
.ant-avatar-image > img {
  object-fit: cover;
}

.ant-card-head-title {
  font-size: 18px;
}

/* Only bold the nested card titles */
.ant-card .ant-card .ant-card-head-title {
  font-weight: bold;
}

/* AntD Tabs overwrite */
.ant-table-tbody {
  background-color: white;
}

tr.ant-table-expanded-row {
  background-color: #fafafa;
}

tr.ant-table-expanded-row:hover {
  background-color: #dfe7ed;
}

.ant-table-thead > tr {
  background-color: #dfe7ed;
}

.ant-table-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-table-tbody > tr > td > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-table table {
  table-layout: fixed;
}

.tabs-wrapper > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.tabs-wrapper > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.tabs-wrapper > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.tabs-wrapper > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.tabs-wrapper > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
  font-size: 1.2rem;
}

/* Increases the size of Antd's badge */
.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.blur {
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

/* TreeCheckBoxFilter Overwrites */
.tree-checkbox-filter .ant-tree li span.ant-tree-switcher {
  display: inline-block;
}
.tree-checkbox-filter {
  min-width: 12%;
}
@media screen and (min-width: 1024px) {
  .tree-checkbox-filter {
    max-width: 35%;
  }
}
@media screen and (min-width: 1200px) {
  .tree-checkbox-filter {
    max-width: 25%;
  }
}
@media screen and (max-width: 375px) {
  .saveDraftBtn {
    padding-left: 0 !important;
    margin-top: 0 !important;
    display: block !important;
  }
}
.tree-checkbox-filter .ant-select-selection {
  border: 1px solid #3b86ff;
}
/* TreeCheckBoxFilter -- custom padding for container and treenode */
.ant-popover-wrapper.tree-checkbox-filter .ant-popover-inner-content {
  padding: 4px;
}
.ant-popover-wrapper.tree-checkbox-filter .ant-popover-inner-content .ant-tree .ant-tree-treenode {
  padding: 4px 0;
}
/* TreeCheckBoxFilter -- hide tree expand icon on expandable = false */
.ant-tree.hide-expand-icon span.ant-tree-switcher {
  display: none;
}

/* Carousel Overwrites */
.slick-slider {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-list {
  display: inline-block !important;
  width: 100% !important;
}

/* Animation section */
@keyframes verticalhop {
  from {
    bottom: 8px;
  }
  50% {
    bottom: -8px;
    border-color: #1890ff;
  }
  to {
    bottom: 8px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}

/* Page Animation */
/* For page transition - enter transition 300ms, exit 0ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out 0ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  overflow-y: hidden;
  transition: opacity 0ms ease-in-out;
}

.no-border {
  border: none !important;
}

.underline-on-hover:hover, button.underline-on-hover:hover, button.underline-on-hover:hover span {
  text-decoration: underline !important;
}

.light-brand-on-hover:hover {
  color: #69e4a6cc !important;
}
.light-grey-on-hover:hover {
  color: #00000073 !important;
}

/* Image wrapper for native HTML button but mutes all native styles */
.button-img-wrapper {
  border: none;
  background-color: inherit;
}
.button-img-wrapper:focus {
  border: none;
  outline: none;
}

.hover-shadow:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.normal-white-space {
  white-space: normal !important;
}

.sr-autocomplete-caret {
  color: rgba(0, 0, 0, 0.25);
}
