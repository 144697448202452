/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Top nav menu */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-top-nav-menu {
  background-color: inherit;
  border: none;
  padding: 0 16px;
  height: 64px;
  line-height: 64px;
  border-color: #001529 !important;
  color: white;
  opacity: 0.65;
  cursor: pointer;
}

.v2-top-nav-menu:hover {
  color: white;
  opacity: 1;
}

.v2-top-nav-menu-selected {
  background-color: inherit;
  border: none;
  padding: 0 16px;
  height: 64px;
  line-height: 64px;
  border-color: #001529 !important;
  color: #00ca7f;
  opacity: 1;
  cursor: pointer;
}

.v2-top-nav-menu-selected:hover {
  color: #00ca7f;
  opacity: 1;
}

.v2-top-nav-menu-selected-line {
  width: 100%;
  background-color: #00ca7f;
  position: absolute;
  bottom: 2px;
  height: 4px;
}

.v2-top-nav-menu-dropdown {
  margin-left: -4px;
  margin-right: -4px;
  row-gap: 0px;
  background: #001529;
  border-radius: 8px;
  margin-top: 16px;
  display: block;
}

/* Dropdown menu (User logout / TopNavMenu) */
.v2-user-dropdown-menu {
  background: #001529;
  margin-top: 5px;
  border-radius: 4px;
}

.v2-user-dropdown-menu > .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  background-color: inherit;
}

/* Dropdown menu (TopNavMenu) */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 0 !important;
}

/* TopNavDropdown container */
.v2-topnav-dropdown {
  padding-left: 4px;
}

/* Normal state */
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: white;
  opacity: 1;
}

/* On hover */
.ant-menu-horizontal > .ant-menu-item a {
  color: white;
  opacity: 0.65;
}

/* Selected */
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #00ca7f !important;
  opacity: 1 !important;
}

/* Normal state */
.v2-user-dropdown-menu > .ant-dropdown-menu-item,
.v2-top-nav-menu > .ant-menu-item > a > span {
  color: white;
  opacity: 0.65;
}

/* Active */
.v2-user-dropdown-menu > .ant-dropdown-menu-item:hover,
.v2-top-nav-menu > .ant-menu-vertical > .ant-menu-item-active > a > span,
.v2-top-nav-menu > .ant-menu-submenu-active > div > span,
.v2-top-nav-menu > .ant-menu-item-active > a > span {
  background-color: inherit;
  opacity: 1 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Tab panels (SR-detail page) */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-funnel-tabs .ant-tabs-ink-bar {
  background-color: #00ca7f !important;
}

.v2-funnel-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.v2-funnel-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border: 1px solid white;
  border-radius: 4px;
}

.v2-funnel-tabs .ant-tabs-tab-btn {
  flex: 1;
}

.v2-funnel-tabs .ant-tabs-tab {
  padding: 0px;
  margin: 0px;
  flex: 1;
}

/* Adding space between Accepted and Inactive funnel tabs */
.v2-funnel-tabs .ant-tabs-tab:nth-last-child(2) {
  margin-left: 20px;
}

.v2-funnel-tabs > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  border-bottom-left-radius: 4px;
  height: 5px;
}

.arrow_box {
  position: relative;
}

.arrow_box:after {
  right: 0;
  top: 50%;
  content: '';
  border: 1px solid transparent;
  transform: translate(50%, -50%) rotate(45deg);
  position: absolute;
  background: #fff;
  border-right: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  width: 15px;
  height: 15px;
}

.arrow_box:before {
  margin-left: 100px;
}

.selected_funnel:after {
  background: #e6fff1;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* General Tab panels */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00ca7f;
}

.ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #00ca7f;
}

.v2-tab-wrapper .ant-tabs-ink-bar {
  background-color: #00ca7f !important;
}

.v2-tab-wrapper .ant-tabs-tab-active {
  color: #00ca7f;
  font-weight: bold;
}

.v2-tab-wrapper .ant-tabs-tab:hover {
  color: #00ca7f;
}

.default-ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
}

.default-ant-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #1890ff;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Primary button */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-btn-v2-primary {
  background-color: #00ca7f;
  border: 1px solid #5ecc95;
  color: white;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn-v2-primary:hover {
  background-color: #00ca7fcc;
  color: white;
  border-color: transparent;
}

.ant-btn-v2-primary:focus {
  background-color: #5ecc95;
  color: white;
  border-color: transparent;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Popup Confirm */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.popup-v2-wrapper .ant-popover-inner-content {
  padding: 32px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Disabled table row for AntD's table
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.disable-table-row {
  background-color: #fafafa;
  cursor: not-allowed;
}

.disable-table-row .ant-table-selection-column > span {
  visibility: hidden;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Radio buttons
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-radio-wrapper .ant-radio-button-wrapper-checked:hover,
.v2-radio-wrapper .ant-radio-button-wrapper-checked {
  color: white !important;
  background-color: #00ca7f;
  border-color: #5ecc95 !important;
}

.v2-radio-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #00ca7f;
}

.v2-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #5ecc95 !important;
}

.v2-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #5ecc95 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Checkbox
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.v2-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5ecc95 !important;
  border-color: #5ecc95 !important;
}

.v2-checkbox-wrapper .ant-checkbox-checked::after {
  border: 1px solid #5ecc95 !important;
}

.v2-checkbox-wrapper:hover .ant-checkbox-inner,
.v2-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #5ecc95 !important;
}

.v2-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #5ecc95 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Avatar
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-avatar-wrapper > img {
  object-fit: cover !important;
}
.v2-avatar-logo-wrapper > img {
  object-fit: none !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Pop over
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-popover-wrapper > .ant-popover-content > .ant-popover-arrow {
  visibility: hidden;
}
.ant-popover-wrapper > .ant-popover-content > .ant-popover-inner > div > .ant-popover-inner-content {
  padding: 0 4px;
}
.ant-popover-wrapper.ant-popover-placement-bottomLeft {
  padding-top: 0;
}

.ant-popover-wrapper-search > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content {
  width: 100%;
  padding: 2px 0;
}

.ant-popover-search-spinner {
  padding: 30px 0px;
}

.ant-popover-search-spinner > section {
  position: relative !important;
  margin-top: 50px;
}

.ant-popover-search-spinner > section > img {
  margin-bottom: 0 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Select Dropdown
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-select-wrapper .ant-select-dropdown,
.v2-select-wrapper > div > div,
.v2-select-wrapper > div {
  position: relative !important;
}
.v2-shifts-week-select > .ant-select-selection {
  border: none;
  margin-right: 8px;
}
.v2-shifts-week-select
  > .ant-select-selection
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Forms
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-form-wrapper .ant-form-item {
  padding: 0;
  margin-bottom: 16px;
}
.v2-form-wrapper .ant-form-item-label {
  line-height: initial;
}
.v2-end-employment-modal .ant-modal-footer {
  text-align: right;
}
.v2-add-entry-modal .ant-modal-footer {
  text-align: right;
}

/* TODO: remove this once FilterWithSearch component is refactored out as helpster-components library */
/* for Worker Profile page filter dropdown */
.filter-dropdown-wrapper > div {
  position: relative;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Checkable Tag */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-checkable-tag-wrapper > .ant-tag-checkable:hover {
  cursor: pointer;
}
.v2-checkable-tag-wrapper > .ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: white;
  background-color: #00ca7f !important;
  border-color: #5ecc95 !important;
  font-weight: bold;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Typography */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-typography {
  font-family: 'Work Sans', sans-serif;
}
.v2-article-wrapper > p {
  line-height: 22px;
  white-space: pre-wrap;
}
.v2-article-wrapper > ul > li {
  margin: 4px 0 0 20px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Table */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-table-column-text-centered {
  text-align: center;
}

.ant-table-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-table-tbody > .attendance-summary-table-expanded-row > td:nth-child(2) {
  padding: 0;
}

.v2-attendance-table table, .v2-attendance-table  td{
  height: 100%;
}

.site-collapse-custom-collapse .ant-collapse-header {
  position: relative;
}

.v2-assignment-table .ant-table-thead > tr > th .ant-table-header-column {
  width: 100%;
}

.schedules-list-table-v2 td.ant-table-row-cell-break-word:nth-child(1) {
  display: flex;
  flex-direction: row;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Steps */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.v2-steps-wrapper > .ant-steps-item-process .ant-steps-item-icon {
  background: #00ca7f;
  border-color: #00ca7f;
}

.v2-steps-wrapper > .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00ca7f;
  color: #00ca7f;
}

.v2-steps-wrapper > .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #00ca7f;
}

.v2-steps-wrapper > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #00ca7f;
}

.v2-steps-wrapper
  > .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #00ca7f;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Layout header */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.ant-layout-header {
  height: 64px;
  padding: 0 20px;
  line-height: 64px;
  background: #001529;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd table row hover */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.schedules-list-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-content
  > table
  > .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: unset;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Shifts table weekly view hover */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.schedules-list-table-v2
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr.ant-table-expanded-row
  > td {
  background: unset;
  background-color: #fafafa;
}

.schedules-list-table-v2
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: unset;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Shifts table mothly view hover */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.schedules-list-table-v2
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr.ant-table-expanded-row
  > td {
  background: unset;
  background-color: #fafafa;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd select */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.workforce-filter-selector
  > .ant-select-selection
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value {
  padding-right: 8px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Antd spinner */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.total-workforce-card-spinner > .ant-spin-dot > .ant-spin-dot-item {
  background-color: white;
}

/* Antd Collapse and Panel */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: #ffffff;
  border-radius: 2px;
  padding-bottom: 8px;
  border: 0px;
  overflow: hidden;
  border-bottom: 1px solid #f5f5f5;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Assignment Table Cell */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.unpublished-cell-border {
  position: relative;
}

.unpublished-cell-border:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #1890ff;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Worker profile modal */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.worker-profile-modal .ant-modal-header {
  padding: 0;
}

.application-modal > .ant-list-item-meta > .ant-list-item-meta-content > .ant-list-item-meta-title {
  margin: 0 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Chat */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-drawer-header > .ant-drawer-header-title > button {
  position: absolute;
  margin-left: 87%;
}

.chat-drawer .ant-drawer-body {
  overscroll-behavior: none;
}

.chat-drawer .group-chat-name input:focus {
  box-shadow: none !important;
}

.chat-avatar {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.chat-avatar .v2-avatar-wrapper {
  margin-bottom: 5px;
}

.chat-avatar .name {
  font-size: 11.5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.chat-avatar .ant-tag {
  font-size: 12px;
  margin-right: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.clickable-chat-avatar > img {
  object-fit: cover !important;
}

.clickable-chat-avatar:hover {
  cursor: pointer;
  border: 4px solid #00ca7f;
}

.chat-list-button {
  border: 0 none;
  color: #a5acb3;
}

.chat-list-button:hover {
  background-color: #32406e !important;
  color: #a5acb3;
}

.chat-list-button.active,
.chat-list-button:focus,
.chat-list-button:active {
  background-color: #164e50 !important;
  color: #00ca7f;
}

.ant-tooltip {
  z-index: 100000 !important;
}

.chat-list-item {
  cursor: pointer;
}
.chat-list-item .chat-list-item-actions {
  visibility: hidden;
}
.chat-list-item:hover {
  background-color: #f5f5f5;
}
.chat-list-item:hover .chat-list-item-actions {
  visibility: visible;
}

.chat-list-item .job-info,
.chat-list-item .name,
.chat-list-item .message,
.chat-list-item .timestamp {
  white-space: nowrap;
  width: 100%;
}

.chat-list-item .job-info {
  color: #9a9a9a;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.chat-list-item .name {
  font-size: 14px;
  font-weight: 800;
}

.chat-list-item .message {
  color: #9a9a9a;
  font-size: 13px;
  margin-bottom: 0;
}

.chat-list-item.unread .message {
  font-weight: 700;
}

.chat-list-item .timestamp {
  color: #9a9a9a;
  font-size: 13px;
  text-align: right;
}

.chat-info-button:hover,
.chat-info-button:focus {
  color: #40a9ff;
  background-color: #ffffff;
  border-color: #40a9ff !important;
}

.chat-info-member-actions {
  z-index: 99999;
}

#chat-box {
  box-shadow: none;
}

.chat-msg-info-dropdown-btn:hover {
  background-color: #e6f7ff;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Chat Image Upload */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.chat-image-uploader,
.chat-image-uploader > .ant-upload-select-picture-card {
  width: 100%;
  height: 322px;
}
.chat-image-uploader > .ant-upload-select-picture-card > .ant-upload {
  height: inherit;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Chat Success/Fail Prompt message */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-message {
  z-index: 100000;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  width: 100%;
  pointer-events: none;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Job Details */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.advanced-settings-header {
  color: #1890ff !important;
}

.advanced-settings-content .ant-collapse-content-box {
  padding: 0;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Job Details Review */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.job-details-review {
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .v2-funnel-tabs .ant-tabs-tab:nth-last-child(2) {
    margin-left: 6px;
  }
}

@media only screen and (min-width: 992px) {
  /* lg */
  .job-details-review {
    margin: auto auto auto 0;
    max-width: 70%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* DropdownButtons component */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.dropdown-buttons-overlay {
  z-index: 99999;
}

.refresh-notif .ant-message-notice-content .ant-message-success {
  display: flex;
  align-items: center;
}

.refresh-notif .ant-message-notice-content .ant-message-success .ant-row .ant-col {
  display: flex;
  align-items: center;
}

.hire-modal-dropdown .ant-collapse .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
  padding-bottom: 0;
  color: #1890ff;
}

.hire-modal-dropdown .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding-left: 0;
}

.break-dropdown {
  max-height: 300px;
  overflow: auto;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Notification message component */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.ant-notification-topCenter {
  left: 50%;
  transform: translateX(-50%);
  top: 16px !important;
  right: auto !important;
  bottom:  auto !important;
}

.v2-notication.ant-notification-notice {
  padding: 10px 16px;
}

.v2-notication .ant-notification-notice-message {
 font-size: 14px;
 margin-left: 40px;
}

.v2-notication .ant-notification-notice-icon {
  font-size: 16px;
}

.v2-timepicker .ant-picker-time-panel-column {
  overflow-y: auto;
}
/* hide default arrow buttons to input */
.v2-input .ant-input-number-handler-wrap {
  display: none;
}

.v2-input .ant-input-number-input {
  text-align: inherit;
}

@media screen and (max-width: 600px) {
  .ant-select-lg.v2-input, .ant-input-lg.v2-input, .ant-input-number-lg.v2-input {
    font-size: 14px;
  }

  .ant-picker-large.v2-input input {
    font-size: 14px;
  }

  .ant-input-lg.v2-input, .ant-picker-large.v2-input  {
    padding: 8px 11px;
  }
}